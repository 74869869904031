var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.urlList.length > 0
        ? _c(
            "el-row",
            _vm._l(_vm.urlList, function (item) {
              return _c(
                "el-col",
                { key: item.cashingId, attrs: { span: 4 } },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: item.urlPathPrefix + item.urlPath,
                      "preview-src-list": _vm.previewList,
                    },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _vm.loading
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c("el-alert", {
                    attrs: { title: "加载中...", closable: false, center: "" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 6 } },
                [
                  _c("el-alert", {
                    attrs: { title: "暂无截图", closable: false, center: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }