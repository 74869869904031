<template>
  <div>
    <el-row v-if="urlList.length > 0">
      <el-col
          :span="4"
          v-for="item in urlList"
          :key="item.cashingId">
          <el-image
            style="width: 100px; height: 100px"
            :src="item.urlPathPrefix + item.urlPath"
            :preview-src-list="previewList">
          </el-image>
        </el-col>
    </el-row>
    <el-row
      v-else-if="loading">
      <el-col :md="6">
        <el-alert
          title="加载中..."
          :closable="false"
          center
        ></el-alert>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col :md="6">
        <el-alert
          title="暂无截图"
          :closable="false"
          center
        ></el-alert>
      </el-col>
    </el-row>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && Array.isArray(val)) {
          this.urlList = val;
          val.forEach((item) => {
            this.previewList.push(item.urlPathPrefix + item.urlPath);
          });
          this.loading = false;
        }
      },
    },
  },
  data() {
    return {
      urlList: [],
      // 预览
      previewList: [],
      // 加载中
      loading: true,
    };
  },
};
</script>
