<script>
import TablePage from '../../../../../../components/table_page';
import * as utils from '../../../../utils/index';

import Form from './form.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaAsExecutionController/cashReport',
      formConfig: {},
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    cellClick({ row, column }) {
      if (column.property === 'activityName') {
        this.formName = 'Form';
        this.formConfig.type = 'view';
        this.formConfig.id = row.id;
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
  created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    this.getConfigList('sfaCenter_activityActivityReport_activityCashDetail');
  },

};
</script>
