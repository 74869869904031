<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import TransImage from './component/trans_image.vue';

formCreate.component('transImage', TransImage);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    getDetail(id) {
      const requestUrl = `/sfa/sfaAsExecutionController/findByCashId/${id}`;
      request.get(requestUrl).then((res) => {
        this.setValue(res.result);
        this.setValue({
          transImage: res.result.asCashingPictureList,
        });
      });
    },
  },
  created() {
    this.getFormRule('sfaCenter_activityActivityReport_activityCashDetail_form')
      .then((res) => {
        res.forEach((item) => {
          const v = item;
          v.props = {
            ...v.props,
            disabled: false,
            readonly: true, // 查看时只读
          };
        });
        this.getDetail(this.formConfig.id);
      });
  },
};
</script>
